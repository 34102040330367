import React, { useContext, useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
import { NavLink, useHistory, useRouteMatch } from "react-router-dom";

import AlertIcon from "../../../images/alert-circle.svg";
import { handleNavConfirm, hasAirbnbRequiredFields } from "./helpers";
import { HomeInfoContext } from "./HomeInfoContext";
import { isInViewport } from "./isInViewport";
import { ListingPhotosContext } from "./ListingPhotosContext";
import {
  HOME_INFO_SECTIONS_PROPERTY,
  HOME_INFO_SECTIONS_CONFIGURATION,
  HOME_INFO_SECTION_OPERATE,
  HOME_INFO_SECTION_AMENITIES,
  HOME_INFO_SECTION_PLATFORM_SETTINGS,
  HOME_INFO_SECTION_CLEANING,
  HOME_INFO_SECTION_INTERNAL
} from "./sections";

const scrollToLink = (match, ref, navRef) => {
  if (!match) {
    return false;
  }

  if (ref && navRef && !isInViewport(ref, navRef, 40)) {
    navRef.scroll({
      top: ref.offsetTop,
      behavior: "smooth"
    });
  }

  return true;
};

const OnboardingNavLink = ({
  section: { path, label, airbnbSyncRequiredFields = {} },
  navigationElementRef
}) => {
  const ref = useRef();
  const history = useHistory();
  const {
    homeInfo,
    isModified,
    setIsModified,
    sidebarDraft,
    setSidebarDraft
  } = useContext(HomeInfoContext);
  const { numberOfAirbnbPhotos } = useContext(ListingPhotosContext);

  const routeMatch = useRouteMatch(`/${path}`);

  const hideMenu = () => {
    const element = document.getElementById("navbarSupportedContent");
    element.classList.remove("show");
  };

  const onClick = (event) => {
    event.preventDefault();
    if (isMobile) {
      hideMenu();
    }

    handleNavConfirm({
      sidebarDraft,
      setSidebarDraft,
      history,
      path,
      isModified,
      setIsModified
    });
  };

  const isActiveHandler = (match) => {
    if (isMobile) {
      return null;
    }

    return !!match;
  };

  useEffect(() => {
    if (!isMobile) {
      scrollToLink(routeMatch, ref.current, navigationElementRef.current);
    }
  }, [routeMatch, navigationElementRef]);

  const hasRequiredFields = hasAirbnbRequiredFields(
    numberOfAirbnbPhotos,
    homeInfo,
    airbnbSyncRequiredFields
  );

  return (
    <div ref={ref} key={path}>
      <NavLink
        to={path}
        className="nav-link"
        onClick={onClick}
        isActive={(match) => isActiveHandler(match)}
        activeClassName="active"
      >
        {label}
        {!hasRequiredFields && <img src={AlertIcon} />}
      </NavLink>
    </div>
  );
};

export const HomeInfoNavigation = () => {
  return (
    <>
      <HomeInfoNavigationMobile />
      <HomeInfoNavigationDesktop />
    </>
  );
};

export const HomeInfoNavigationDesktop = () => {
  const { property } = useContext(HomeInfoContext);
  const ref = useRef(null);

  return (
    <div className="navigation" ref={ref}>
      <h4>
        <a href={`/properties/${property.id}`}>
          <i className="fa fa-caret-left" /> {property.home_code}
        </a>
      </h4>

      <h5>
        <div className="nav flex-column nav-pills">
          <NavLink to="/" exact className="nav-link" activeClassName="active">
            Preview
          </NavLink>
        </div>
      </h5>

      <div>
        <h5>Property</h5>

        <div className="nav flex-column nav-pills">
          {HOME_INFO_SECTIONS_PROPERTY.map((section) => (
            <OnboardingNavLink
              key={section.label}
              section={section}
              navigationElementRef={ref}
            />
          ))}
        </div>

        <h5>Configuration</h5>

        <div className="nav flex-column nav-pills">
          {HOME_INFO_SECTIONS_CONFIGURATION.map((section) => (
            <OnboardingNavLink
              key={section.label}
              section={section}
              navigationElementRef={ref}
            />
          ))}
        </div>

        <h5>Operate</h5>

        <div className="nav flex-column nav-pills">
          {HOME_INFO_SECTION_OPERATE.map((section) => (
            <OnboardingNavLink
              key={section.label}
              section={section}
              navigationElementRef={ref}
            />
          ))}
        </div>

        <h5>Amenities</h5>

        <div className="nav flex-column nav-pills">
          {HOME_INFO_SECTION_AMENITIES.map((section) => (
            <OnboardingNavLink
              key={section.label}
              section={section}
              navigationElementRef={ref}
            />
          ))}
        </div>

        <h5>Platform settings</h5>

        <div className="nav flex-column nav-pills">
          {HOME_INFO_SECTION_PLATFORM_SETTINGS.map((section) => (
            <OnboardingNavLink
              key={section.label}
              section={section}
              navigationElementRef={ref}
            />
          ))}
        </div>

        <h5>Cleaning</h5>

        <div className="nav flex-column nav-pills">
          {HOME_INFO_SECTION_CLEANING.map((section) => (
            <OnboardingNavLink
              key={section.label}
              section={section}
              navigationElementRef={ref}
            />
          ))}
        </div>

        <h5>Internal</h5>

        <div className="nav flex-column nav-pills">
          {HOME_INFO_SECTION_INTERNAL.map((section) => (
            <OnboardingNavLink
              key={section.label}
              section={section}
              navigationElementRef={ref}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export const HomeInfoNavigationMobile = () => {
  const { property } = useContext(HomeInfoContext);
  const ref = useRef(null);

  return (
    <nav className="navbar fixed-top navbar-expand-lg navbar-light bg-light ">
      <div className="top-bar">
        <a className="navbar-brand logo" href="/">
          {" "}
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
      </div>

      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav mr-auto">
          <li className="nav-item active">
            <a className="nav-link" href={`/properties/${property.id}`}>
              <i className="fa fa-caret-left" /> {property.home_code}
            </a>
          </li>
          <li className="nav-item">
            <NavLink to="/" exact className="nav-link" activeClassName="active">
              Preview
            </NavLink>
          </li>
          <li className="nav-item dropdown">
            <div
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Property
            </div>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              {HOME_INFO_SECTIONS_PROPERTY.map((section) => (
                <OnboardingNavLink
                  key={section.label}
                  section={section}
                  navigationElementRef={ref}
                />
              ))}
            </div>
          </li>

          <li className="nav-item dropdown">
            <div
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Configuration
            </div>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              {HOME_INFO_SECTIONS_CONFIGURATION.map((section) => (
                <OnboardingNavLink
                  key={section.label}
                  section={section}
                  navigationElementRef={ref}
                />
              ))}
            </div>
          </li>

          <li className="nav-item dropdown">
            <div
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Operate
            </div>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              {HOME_INFO_SECTION_OPERATE.map((section) => (
                <OnboardingNavLink
                  key={section.label}
                  section={section}
                  navigationElementRef={ref}
                />
              ))}
            </div>
          </li>

          <li className="nav-item dropdown">
            <div
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Amenities
            </div>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              {HOME_INFO_SECTION_AMENITIES.map((section) => (
                <OnboardingNavLink
                  key={section.label}
                  section={section}
                  navigationElementRef={ref}
                />
              ))}
            </div>
          </li>

          <li className="nav-item dropdown">
            <div
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Cleaning
            </div>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              {HOME_INFO_SECTION_CLEANING.map((section) => (
                <OnboardingNavLink
                  key={section.label}
                  section={section}
                  navigationElementRef={ref}
                />
              ))}
            </div>
          </li>

          <li className="nav-item dropdown">
            <div
              className="nav-link dropdown-toggle"
              id="navbarDropdown"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Internal
            </div>
            <div className="dropdown-menu" aria-labelledby="navbarDropdown">
              {HOME_INFO_SECTION_INTERNAL.map((section) => (
                <OnboardingNavLink
                  key={section.label}
                  section={section}
                  navigationElementRef={ref}
                />
              ))}
            </div>
          </li>
        </ul>
      </div>
    </nav>
  );
};
